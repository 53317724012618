












import Vue from "vue";

export default Vue.extend({
    props: ["value", "label", "accept"],
    data() {
        return {
            base64Value: undefined,
            fileInput: undefined,
        };
    },
    created() {
        this.base64Value = this.value;
    },
    methods: {
        async beginUpdateValue() {
            if (this.fileInput) {
                this.updateValue(await this.readFile(this.fileInput));
            } else {
                this.updateValue("");
            }
        },
        updateValue(value) {
            this.base64Value = value;
            this.$emit("input", value);
        },
        reset() {
            this.fileInput = ""
        },
        readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
    },
});
