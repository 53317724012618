
































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import ImageInput from "@/views/app/bill/ImageInput.vue";
import Base64FileInput from "./Base64FileInput.vue";
import moment from "moment";

export default Vue.extend({
  components: {
    ImageInput,
    Base64FileInput,
  },
  data() {
    const self: any = this;
    return {
      self,
      util,
      userManager,
      model: [],
      currentImages: [],
      currentFile: [],
      dialog: false,
      snackbar: false,
      fails: "",
      name: "",
      msg: "",
      form: false,
      dialogType: false,
      formType: false,
      hideCreate: false,
      loadingCreate: false,
      dialogDelete: false,
      dialogEdit: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      ruleRequiredArray: (value) => !!(Array.isArray(value) && value.length > 0) || this.$t("REQUIRED"),
      headers: [
        { text: "Số xe", value: "vehicle_number", width: 100 },
        { text: "Công ty", value: "truck_company", width: 100 },
        { text: "TT TK", value: "account_number", width: 100 },
        { text: "Mã đơn", value: "bill_code", width: 100 },
        { text: "Mã bill", value: "automatic_bill_code", width: 100 },
        { text: "Kinh doanh", value: "sale_user_id", width: 150 },
        { text: "Khách hàng", value: "customer_id", width: 150 },
        { text: "Trạng thái dấu", value: "customer_stamp_status", width: 150 },
        { text: "Mô tả", value: "description", width: 100 },
        { text: "Số lượng", value: "quantity", width: 100 },
        { text: "Đơn giá bán", value: "unit_price", width: 150 },
        { text: "Thuế suất", value: "tax", width: 150 },
        { text: "Tổng trị giá", value: "total_Value", width: 150 },
        { text: "Mã hàng hóa", value: "commodity_code", width: 150 },
        { text: "Mã hóa đơn", value: "code", width: 150 },
        { text: "Trạng thái", value: "status", width: 100 },
        { text: "Trạng thái gửi yêu cầu", value: "request_status", width: 100 },
        { text: "Hình ảnh", value: "images", width: 100 },
        { text: "Ghi chú", value: "note", width: 100 },
        { text: "Ngày tải", value: "created_at", width: 100 },
        { text: "Ngày ký", value: "sign_date", width: 100 },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      options: {},
      search: {
        status: "",
        code: "",
        bill_code: "",
        automatic_bill_code: "",
        created_time: "",
        vehicle_number: "",
        created_at: "",
      },
      dialogImport: false,
      dialogExport: false,
      dialogNote: false,
      dialogRequest: false,
      dialogDone: false,
      dialogUploadFile: false,
      dialogUploadImages: false,
      dialogSignTheBill: false,
      dialogContractOut: false,
      dialogContractExported: false,
      dialogContractIn: false,
      dialogLiquidity: false,
      formNote: false,
      formUploadImages: false,
      formSignTheBill: false,
      formUploadFile: false,
      loading: false,
      data: [],
      files: "",
      dataCount: 0,
      editedIndex: -1,
      editedItem: {},
      allowDelete: {},
      users: [],
      customers: [],
      listStatus: [
        {
          id: "0",
          name: "Trống",
        },
        {
          id: "1",
          name: "Yêu cầu",
        },
        {
          id: "2",
          name: "Xuất nháp",
        },
        {
          id: "3",
          name: "Đồng ý",
        },
        {
          id: "4",
          name: "Đã ký",
        },
        {
          id: "5",
          name: "Xuất hợp đồng",
        },
        {
          id: "5.5",
          name: "Đã xuất",
        },
        {
          id: "6",
          name: "Nhận hợp đồng",
        },
        {
          id: "7",
          name: "Thanh khoản",
        },
      ],
      urlFunc(item) {
        return Vue.filter("discussionMessageImageUrl")(item);
      },
      availableCompanyMap: {
        dd: "Đại Dương",
        hb: "Hoàng Bảo",
      },
      availableStampStatusMap: {
        yes: "Xác nhận",
        no: "Không cần",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo hướng dẫn" : this.$t("EDIT");
    },
    availableProgresses() {
      const items = [];
      for (const k in this.availableProgressMap) {
        items.push({
          text: this.availableProgressMap[k],
          value: k,
        });
      }
      return items;
    },
    availableRoles() {
      const items = [];
      for (const k in this.availableRoleMap) {
        items.push({
          text: this.availableRoleMap[k],
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogNote(val) {
      val || this.closeNote();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogUploadFile() {
      this.currentFile = [];
      if (this.$refs.filesInput) {
        this.$refs.filesInput.reset();
      }
    },
    dialogUploadImages(val) {
      val || this.closeImages();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      const filters = [];
      const search = Object.assign({ ...this.search });
      for (const searchKey in search) {
        if (search[searchKey]) {
          if (searchKey === "id") {
            search[searchKey] = search[searchKey].replace("HD", "");
          }
          if (
            searchKey === "vehicle_number" ||
            searchKey === "created_at" ||
            searchKey === "bill_code" ||
            searchKey === "code" ||
            searchKey === "automatic_bill_code"
          ) {
            filters.push({
              key: searchKey,
              operator: "LIKE",
              value: `${search[searchKey]}%`,
            });
          } else {
            filters.push({
              key: searchKey,
              operator: "=",
              value: search[searchKey],
            });
          }
        }
      }
      this.loading = true;
      const { items, count } = await apiClient.billsGetAll({
        options: this.options,
        filters,
      });
      this.data = items.map((item) => {
        if (item.images) {
          item.images = JSON.parse(item.images);
        }
        return item;
      });
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      const params = {
        ids: this.model.map((item) => item.id),
      };
      this.closeDelete();
      await apiClient.billsDelete(params);
      await this.initialize();
    },
    async save() {
      let result;
      const userInfo = { ...this.userManager.getUserInfo() };
      if (userInfo.sub) {
        this.editedItem["created_by"] = userInfo.sub;
      }
      if (this.editedIndex > -1) {
        result = await apiClient.processGuiderUpdate(this.editedItem);
      } else {
        result = await apiClient.processGuiderCreate(this.editedItem);
      }
      if (!result.errors) {
        this.close();
        await this.initialize();
      } else {
        this.snackbar = true;
        this.fails = result.message;
      }
    },
    getAllUser() {
      this.usersByRoles = [];
      apiClient
        .userGetAll()
        .then((res) => {
          this.users = [
            {
              id: "all",
              name: "Tất cả",
            },
          ].concat(
            res.items.map((item) => {
              item.id = String(item.id);
              return item;
            })
          );
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    getName(value, data, keyData = "id", keyName = "name") {
      if (value) {
        if (Array.isArray(data)) {
          const length = data.length;
          for (let i = 0; i < length; i++) {
            if (String(data[i][keyData]) === String(value)) {
              return data[i][keyName];
            }
          }
        }
        return value;
      }
      return "";
    },
    closeNote() {
      this.dialogNote = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDone() {
      this.dialogDone = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.model = [];
      });
    },
    closeRequest() {
      this.dialogRequest = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeUploadFile() {
      this.dialogUploadFile = false;
      this.$nextTick(() => {
        this.currentFile = [];
      });
    },
    closeImages() {
      this.dialogUploadImages = false;
      this.$nextTick(() => {
        this.currentImages = [];
      });
    },
    closeSignTheBill() {
      this.dialogSignTheBill = false;
    },
    closeContractOut() {
      this.dialogContractOut = false;
    },
    closeContractExported() {
      this.dialogContractExported = false;
    },
    closeContractIn() {
      this.dialogContractIn = false;
    },
    closeLiquidity() {
      this.dialogLiquidity = false;
    },
    openFile() {
      const f = this.$refs.fileInput;
      f.click();
    },
    async uploadFile() {
      const file = this.$refs.fileInput.files[0];
      if (!file) {
        return;
      }
      const result = await apiClient.billsImportExportInfo(file);
      if (result) {
        await this.initialize();
        await this.closeUploadFile();
      }
    },
    async handleNote() {
      const params = {
        ids: this.model.map((item) => item.id),
        note: this.editedItem.note,
      };
      const result = await apiClient.billsNote(params);
      if (result) {
        await this.initialize();
        await this.closeNote();
        this.model = [];
      }
    },
    async handleRequest() {
      const params = {
        ids: this.model.map((item) => item.id),
        status: "1",
      };
      const result = await apiClient.billsRequest(params);
      if (result) {
        await this.initialize();
        await this.closeRequest();
        this.model = [];
      }
    },
    async handleImages() {
      const params = {
        ids: this.model.map((item) => item.id),
        images: this.currentImages,
        status: "2",
      };
      const result = await apiClient.billsFiles(params);
      if (result) {
        await this.initialize();
        await this.closeImages();
        this.model = [];
      }
    },
    async handleFiles() {
      const params = {
        ids: this.model.map((item) => item.id),
        files: this.currentFile,
        status: "2",
      };
      const result = await apiClient.billsFiles(params);
      if (result) {
        await this.initialize();
        await this.closeUploadFile();
        this.model = [];
      }
    },
    async handleDone(requestStatus) {
      const params = {
        ids: this.model.map((item) => item.id),
        status: "3",
        request_status: requestStatus,
      };
      const result = await apiClient.billsAgree(params);
      if (result) {
        await this.initialize();
        await this.closeDone();
        this.model = [];
      }
    },
    async handleSignTheBill() {
      const params = {
        ids: this.model.map((item) => item.id),
        status: "4",
        sign_date: moment().format("DD/MM/YYYY - HH:mm:ss"),
        code: this.editedItem.code,
      };
      const result = await apiClient.billsSignTheBill(params);
      if (result) {
        await this.initialize();
        await this.closeSignTheBill();
        this.model = [];
      }
    },
    async handleUpdate() {
      const params = {
        ids: [this.editedItem.id],
        status: this.editedItem.status,
        note: this.editedItem.note,
      };
      const result = await apiClient.billsUpdate(params);
      if (result) {
        await this.initialize();
        await this.closeUpdate();
        this.model = [];
      }
    },
    closeUpdate() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async handleContractOut() {
      const params = {
        ids: this.model.map((item) => item.id),
        status: "5",
      };
      const result = await apiClient.billsAgree(params);
      if (result) {
        await this.initialize();
        await this.closeContractOut();
        this.model = [];
      }
    },
    async handleContractExported() {
      const params = {
        ids: this.model.map((item) => item.id),
        status: "5.5",
      };
      const result = await apiClient.billsAgree(params);
      if (result) {
        await this.initialize();
        await this.closeContractExported();
        this.model = [];
      }
    },
    async handleContractIn() {
      const params = {
        ids: this.model.map((item) => item.id),
        status: "6",
      };
      const result = await apiClient.billsAgree(params);
      if (result) {
        await this.initialize();
        await this.closeContractIn();
        this.model = [];
      }
    },
    async handleLiquidity() {
      const params = {
        ids: this.model.map((item) => item.id),
        status: "7",
      };
      const result = await apiClient.billsAgree(params);
      if (result) {
        await this.initialize();
        await this.closeLiquidity();
        this.model = [];
      }
    },
    exportFile() {
      const ids = this.model.map((item) => item.id);

      const result = apiClient.billsExport(ids);
      window.open(result);
    },
    copyTextToClipboard(text, self) {
      navigator.clipboard
        .writeText(text)
        .then(function () {
          self.snackbar = true;
          self.msg = `Sao chép thành công <strong>${text}<strong/>`;
        })
        .finally(() => {
          setTimeout(() => {
            this.msg = "";
          }, 3000);
        });
    },
    downloadFile(item) {
      util.download(`hoa_don_${item.id}.pdf`, item.files, true);
    },
    checkRoles() {
      const roles = userManager.getUserInfo().roles;
      const iggRoles = ["account_number", "sale_user_id", "unit_price", "tax", "total_Value", "commodity_code"];
      if (roles.includes("kd")) {
        this.headers = this.headers.filter((item) => {
          return !iggRoles.includes(item.value) && item.value !== "total_value";
        });
      }
    },
  },
  created() {
    this.getAllUser();
    this.checkRoles();
  },
});
