class Util {
    public download(filename, text, base64Encoded) {
        if (!base64Encoded) {
            text = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
        }
        const element = document.createElement('a');
        element.setAttribute('href', text);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }


    public groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }
}

export default new Util()