

















































import Vue from "vue";
import ExpandableImage from "@/components/ExpandableImage.vue";

export default Vue.extend({
  components: {
    ExpandableImage,
  },
  props: ["value", "label", "multiple", "urlFunc", "col"],
  data() {
    return {
      base64Value: undefined,
      fileInput: undefined,
      items: [],
      version: 0,
      colValue: 6,
    };
  },
  created() {
    if (!this.multiple) {
      this.base64Value = this.value;
    } else {
      if (this.value) {
        this.items = [...this.value];
      }
    }
    if (this.col) {
      this.colValue = this.col;
    }
  },
  methods: {
    async beginUpdateValue() {
      if (!this.multiple) {
        if (this.fileInput) {
          this.updateValue(await this.readFile(this.fileInput));
        } else {
          this.updateValue("");
        }
      } else {
        for (const file of this.fileInput) {
          const item = await this.readFile(file);
          this.items.push({
            data: item,
          });
          this.updateValue(this.items);
        }
        this.fileInput = [];
      }
    },
    updateValue(value) {
      if (!this.multiple) {
        this.base64Value = value;
      }
      this.$emit("input", value);
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    removeImage(item, index) {
        this.items.splice(index, 1);
        this.$emit("input", this.items);
    },
  },
});
